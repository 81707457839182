import './../styles/Footer.css';

import React from 'react';

const Footer = () => (
    <footer className="app-footer">
        El componente Footer
    </footer>
);

export default Footer;